import React from "react";
import "./hero.scss";
import Coin from "../coin/Coin";
import dolar from "../../images/dolar.svg";
import eu from "../../images/eu.svg";
import ru from "../../images/ru.svg";
import yen from "../../images/yen.svg";
import Pop from "../pop/Pop";
const Hero = ({ setOpenC, openC, data }) => {
  return (
    <>
      <div className="hero_container">
        <div className="hero_text">
          <h1>{data.top_header}</h1>
          <p>{data.top_paragraph}</p>
          <button onClick={() => setOpenC(true)}>Get Started</button>
        </div>
        <div className="hero_n_image">
          <svg
            width="750"
            height="500"
            viewBox="0 0 750 500"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 13">
              <g id="Team goals-bro">
                <g id="freepik--background-simple--inject-73">
                  <path
                    id="Vector"
                    d="M676 431.43C712.22 402.43 733.07 358.31 733.71 311.9C734.12 282.64 732.02 245.9 722.19 214.44C689.77 110.71 598.19 54.4399 531.19 42.4399C464.19 30.4399 395.19 52.4399 343.19 87.4399C291.19 122.44 296.19 116.44 207.19 103.44C118.19 90.4399 45.1901 128.44 21.1901 198.44C-2.80993 268.44 70.1901 308.11 78.1901 344.78C86.1901 381.45 72.1901 446.44 211.19 462.44C350.19 478.44 392.19 400.44 461.19 422.44C516.71 440.15 599.43 492.82 676 431.43Z"
                    fill="#4A8ACF"
                  />
                  <path
                    id="Vector_2"
                    opacity="0.7"
                    d="M676 431.43C712.22 402.43 733.07 358.31 733.71 311.9C734.12 282.64 732.02 245.9 722.19 214.44C689.77 110.71 598.19 54.4399 531.19 42.4399C464.19 30.4399 395.19 52.4399 343.19 87.4399C291.19 122.44 296.19 116.44 207.19 103.44C118.19 90.4399 45.1901 128.44 21.1901 198.44C-2.80993 268.44 70.1901 308.11 78.1901 344.78C86.1901 381.45 72.1901 446.44 211.19 462.44C350.19 478.44 392.19 400.44 461.19 422.44C516.71 440.15 599.43 492.82 676 431.43Z"
                    fill="#BCFFF7"
                  />
                </g>
                <g id="freepik--character-3--inject-73">
                  <path
                    id="Vector_3"
                    d="M569.75 419.55C569.75 419.55 563.54 423.55 562.3 424.55C561.06 425.55 555.35 426.79 556.1 428.55C556.85 430.31 564.04 431.28 566.03 431.28C568.02 431.28 575.7 427.28 577.69 426.82C579.68 426.36 579.69 424.09 578.93 422.35C578.11 420.824 577.102 419.406 575.93 418.13L570.72 419.62L569.75 419.55Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_4"
                    d="M640.23 418.87C640.23 418.87 635.51 424.58 634.77 425.82C634.03 427.06 629.77 429.04 629.77 431.03C629.77 433.02 636.97 432.03 639.2 431.77C640.542 431.561 641.819 431.052 642.936 430.279C644.053 429.507 644.981 428.492 645.65 427.31C646.9 424.82 647.89 419.61 646.4 417.63C644.91 415.65 640.23 418.87 640.23 418.87Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_5"
                    d="M580.67 308.93L580.47 310.43L578.93 322.33C578.93 322.33 565.13 341.82 560.08 351.97C557.5 357.15 553.9 363.3 554.4 365.03L569.75 419.55C570.913 420.121 572.177 420.461 573.47 420.55C574.661 420.509 575.836 420.259 576.94 419.81L576.24 369.35L597.54 345.92L600.27 349.39C600.27 349.39 607.34 374.47 610.32 378.44C613.3 382.41 639 419.61 639 419.61C640.324 420.228 641.76 420.569 643.22 420.61C645.46 420.61 646.45 417.61 646.45 417.61C646.45 417.61 627.96 371.96 627.45 370.47C626.94 368.98 627.32 332.47 627.32 332.47L624.32 314.85L620.85 303.68C620.85 303.68 614.4 305.42 603.48 304.92C592.56 304.42 582.14 303.68 582.14 303.68L580.67 308.93Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_6"
                    d="M551.38 189.32C551.38 189.32 547.91 194.53 547.38 197.76C547.173 199.909 547.796 202.055 549.12 203.76L547.12 207.98C547.12 207.98 553.82 221.63 557.79 227.83C561.76 234.03 568.21 244.46 572.18 246.45C576.15 248.44 583.84 256.62 583.84 256.62L588.31 246.94C588.31 246.94 575.21 230.51 567 219.1C562.037 212.173 557.479 204.964 553.35 197.51C553.342 195.35 553.509 193.193 553.85 191.06C554.175 189.174 554.339 187.264 554.34 185.35L551.38 189.32Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_7"
                    d="M591.59 243.17C591.59 243.17 581.41 255.33 579.43 263.27C577.45 271.21 579.92 295.78 579.92 299.27C579.92 302.76 577.92 306.47 580.67 308.95C583.42 311.43 591.59 310.19 594.56 309.95C597.53 309.71 611.69 309.95 615.91 308.7C620.13 307.45 622.11 305.7 622.11 302.75C622.11 299.8 616.9 276.2 616.11 272.23C615.32 268.26 618.11 254.61 614.38 248.15C610.65 241.69 598.78 240.69 591.59 243.17Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_8"
                    d="M589.85 244.91C589.85 244.91 577.44 257.56 575.7 264.26C573.96 270.96 567.76 283.87 565.78 289.58C564.055 294.084 562.051 298.476 559.78 302.73L562.01 311.66L567.72 305.46C567.72 305.46 573.43 285.85 576.41 277.66C579.39 269.47 579.88 261.78 580.41 259.55C580.94 257.32 589.85 244.91 589.85 244.91Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_9"
                    d="M591.59 244.16C591.59 244.16 584.14 264.51 584.39 266C584.64 267.49 588.11 322.58 588.11 322.58C588.11 322.58 591.59 331.02 607.22 329.78C622.85 328.54 635 326.3 635 326.3C635 326.3 630.29 307.69 626.07 299.25C621.85 290.81 619.62 285.85 619.62 281.88C619.62 277.91 617.88 251.36 616.89 247.14C616.458 244.973 615.703 242.883 614.65 240.94L613.17 239.7L591.59 244.16Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_10"
                    d="M585.63 286.35L597.79 316.13C597.79 316.13 592.79 318.13 591.09 319.85C590.137 320.81 589.135 321.721 588.09 322.58L585.63 286.35Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_11"
                    d="M601.76 204.21C601.76 204.21 594.07 210.41 594.07 212.15C594.07 213.89 592.07 216.37 592.07 216.37C592.07 216.37 588.07 216.87 587.85 218.37C587.63 219.87 589.09 222.84 589.09 222.84C589.09 222.84 586.61 231.28 587.61 233.26C588.61 235.24 595.79 235.5 595.79 235.5L596.04 240.96L613.17 238.23L612.92 234.01C612.92 234.01 622.1 224.08 626.57 219.86C631.04 215.64 625.33 208.2 619.87 202.49C614.41 196.78 601.5 196.78 600.51 199.76C600.203 200.537 600.16 201.392 600.385 202.196C600.611 202.999 601.094 203.707 601.76 204.21Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_12"
                    d="M595.31 213.14C595.858 212.867 596.469 212.747 597.079 212.791C597.69 212.835 598.277 213.042 598.78 213.39"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_13"
                    d="M595.57 216.72C595.38 217.52 595.5 218.24 595.83 218.31C596.16 218.38 596.59 217.81 596.78 217.01C596.97 216.21 596.85 215.5 596.52 215.42C596.19 215.34 595.76 215.92 595.57 216.72Z"
                    fill="#263238"
                  />
                  <path
                    id="Vector_14"
                    d="M619.88 202.47C614.42 196.76 601.51 196.76 600.52 199.74C600.208 200.518 600.16 201.377 600.384 202.185C600.608 202.993 601.092 203.704 601.76 204.21C601.76 204.21 610.2 205.21 608.96 207.44C607.72 209.67 603.5 209.67 604.49 211.16C605.48 212.65 608.96 215.87 607.72 216.62C606.48 217.37 605.72 218.85 606.48 219.35C607.24 219.85 608.48 218.6 611.48 219.1C614.48 219.6 613.71 226.54 610.98 227.54C608.25 228.54 606.98 227.29 611.98 231.76C612.567 232.274 613.246 232.675 613.98 232.94C616.46 230.27 623.07 223.23 626.65 219.84C631 215.62 625.34 208.18 619.88 202.47Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_15"
                    d="M608.96 228.28L604.49 227.29L601.51 229.52"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_16"
                    d="M614.17 235.23L591.34 241.43L589.85 246.15L616.9 241.19L614.17 235.23Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_17"
                    d="M556.6 196.76L555.85 191.55C555.85 191.55 558.08 184.36 558.08 182.62C558.08 180.88 556.35 176.91 556.35 175.92C556.35 174.93 554.86 173.44 554.35 175.42C553.84 177.4 552.86 184.85 552.86 184.85C552.86 184.85 549.64 190.31 549.86 193.04C550.08 195.77 552.58 201.48 552.58 201.48L557.05 198.75L556.6 196.76Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_18"
                    d="M559.82 200.74L556.6 196.76L550.64 201.48L554.11 207.44L559.82 200.74Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="Vector_19">
                    <path
                      d="M613.92 251.86C612.274 247.698 609.191 244.264 605.23 242.18C601.011 240.11 596.609 238.437 592.08 237.18L558.33 198.72L551.63 205.42C551.63 205.42 565.78 234.95 568.51 239.91C571.24 244.87 602.75 278.91 602.75 278.91"
                      fill="#4A8ACF"
                    />
                    <path
                      d="M613.92 251.86C612.274 247.698 609.191 244.264 605.23 242.18C601.011 240.11 596.609 238.437 592.08 237.18L558.33 198.72L551.63 205.42C551.63 205.42 565.78 234.95 568.51 239.91C571.24 244.87 602.75 278.91 602.75 278.91"
                      stroke="#263238"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
                <g id="freepik--character-2--inject-73">
                  <path
                    id="Vector_20"
                    d="M319.69 414.44C319.69 414.44 314.94 421.19 313.94 424.94C312.94 428.69 313.19 433.69 315.69 433.19C318.19 432.69 323.94 426.44 324.44 424.69C324.94 422.94 326.69 414.94 326.69 414.94L320.69 414.44H319.69Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_21"
                    d="M405.44 418.69C405.44 418.69 405.44 425.69 406.94 426.69C408.44 427.69 418.69 431.94 421.69 432.19C424.69 432.44 425.69 431.94 423.94 428.44C422.19 424.94 412.19 418.19 412.19 418.19L405.44 418.69Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_22"
                    d="M343.94 300.44L338.19 324.69L335.19 370.19C335.19 370.19 324.19 389.19 323.19 396.19C322.19 403.19 318.94 417.44 318.94 417.44C318.94 417.44 323.44 419.44 324.19 419.44C324.94 419.44 333.94 406.44 338.94 401.44C343.94 396.44 355.19 374.94 355.69 373.94C356.19 372.94 364.19 341.19 364.69 340.19C365.19 339.19 368.19 337.94 368.19 337.94C368.19 337.94 383.25 359.59 385.07 361.41C387.98 364.32 392.69 371.69 392.69 371.69C392.69 371.69 402.94 412.44 403.69 414.44C404.233 416.314 404.568 418.242 404.69 420.19L413.94 422.19C413.94 422.19 414.69 394.94 414.19 388.44C413.69 381.94 411.69 367.94 411.69 367.94C411.69 367.94 396.44 326.19 395.69 325.19C394.94 324.19 390.44 314.19 390.44 314.19L388.94 304.69C380.146 307.154 370.892 307.497 361.94 305.69C346.94 302.69 343.94 300.44 343.94 300.44Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_23"
                    d="M358.07 232.65C352.639 236.24 347.003 239.509 341.19 242.44C333.52 246.11 331.69 249.78 331.69 251.61C331.69 253.44 325.02 283.78 325.52 285.44C326.02 287.1 330.52 289.61 333.36 290.28C336.2 290.95 340.19 290.11 341.02 289.44C341.85 288.77 344.52 277.44 344.52 277.44C344.52 277.44 343.02 297.94 342.36 300.61C341.7 303.28 347.86 310.11 353.86 311.28C359.86 312.45 376.02 312.44 380.19 311.11C384.36 309.78 387.19 309.44 388.52 306.94C389.85 304.44 390.69 286.61 390.86 285.44C391.03 284.27 392.36 273.61 392.36 273.61L396.69 288.28L398.36 291.28C398.36 291.28 399.19 289.44 404.36 286.44C409.53 283.44 411.19 283.11 411.19 283.11C411.19 283.11 408.19 273.28 406.36 267.61C404.53 261.94 402.69 251.61 400.36 247.44C398.03 243.27 393.86 241.44 388.19 237.94C382.52 234.44 374.19 232.44 373.19 231.94C372.19 231.44 363.28 230.7 358.07 232.65Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_24"
                    d="M344.52 277.44L348.04 265.25"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_25"
                    d="M394.8 262.23L395.4 259.47"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_26"
                    d="M392.36 273.61L394.27 264.73"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_27"
                    d="M362.44 251.94L365.94 256.94L367.94 261.44L362.94 302.94L369.94 309.44L375.44 302.19L370.44 261.94L373.44 256.19L377.43 249.66L370.19 245.44L362.44 251.94Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_28"
                    d="M360 230.86C360 230.86 356.53 231.77 355.77 233.86C355.01 235.95 356.07 248.67 357.13 251.39C357.993 253.433 358.995 255.414 360.13 257.32C360.13 257.32 369.41 251.39 371.07 251.09C372.73 250.79 382.26 255.78 382.26 255.78C382.26 255.78 382.56 249.88 379.26 241.57C375.96 233.26 368.26 228.57 364.9 228.26C363.92 228.178 362.937 228.38 362.068 228.841C361.2 229.302 360.482 230.003 360 230.86Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_29"
                    d="M358.62 229.81C358.62 229.81 359.08 235.1 359.53 238.72C359.98 242.34 365.58 254.3 367.53 255.96C369.48 257.62 373.53 252.18 374.94 249.76C375.534 248.659 376.202 247.6 376.94 246.59C376.94 246.59 367.27 241.9 364.25 236.46C361.23 231.02 361.52 228.46 360.16 228.29C358.8 228.12 358.62 229.81 358.62 229.81Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_30"
                    d="M363.5 242.81L370.93 254.81C370.93 254.81 374.35 251.65 374.93 249.81C375.51 247.97 363.5 242.81 363.5 242.81Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_31"
                    d="M355.71 221.32C355.065 223.053 354.822 224.91 355 226.75C355.23 230.06 357.12 232.42 358.07 232.65C358.927 232.812 359.798 232.892 360.67 232.89C360.67 232.89 360.9 238.79 363.5 242.81C366.1 246.83 374.84 252.49 377.43 249.66C380.02 246.83 383.34 235.49 384.52 230.06C385.262 226.549 385.813 223.001 386.17 219.43L368.7 215.43L360.43 213.55L355.71 221.32Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_32"
                    d="M365.86 219C365.86 219 366.81 222.07 364.92 224C363.03 225.93 362.32 225.89 362.32 225.89C362.32 225.89 362.79 228.96 362.08 228.96C361.768 228.965 361.458 228.908 361.168 228.79C360.879 228.673 360.616 228.499 360.396 228.277C360.175 228.056 360.002 227.793 359.886 227.503C359.77 227.213 359.713 226.902 359.72 226.59C359.659 225.324 359.251 224.099 358.54 223.05L355.71 221.4C355.024 217.962 355.102 214.415 355.94 211.01C357.36 205.58 367.51 203.45 376.02 203.69C384.53 203.93 386.64 214.79 386.64 214.79C388.44 215.605 390.178 216.551 391.84 217.62C392.55 218.33 393.26 219.98 389.24 222.11C385.22 224.24 378.14 224.94 373.89 223.29C371.075 222.136 368.384 220.699 365.86 219Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_33"
                    d="M371.59 231.56C371.31 232.44 370.74 233.04 370.3 232.91C369.86 232.78 369.73 231.91 370 231.07C370.27 230.23 370.85 229.59 371.28 229.73C371.71 229.87 371.86 230.69 371.59 231.56Z"
                    fill="#263238"
                  />
                  <path
                    id="Vector_34"
                    d="M381.41 232.32C381.14 233.2 380.56 233.8 380.13 233.66C379.7 233.52 379.55 232.66 379.82 231.83C380.09 231 380.67 230.35 381.11 230.48C381.55 230.61 381.68 231.44 381.41 232.32Z"
                    fill="#263238"
                  />
                  <path
                    id="Vector_35"
                    d="M368 228.6C368 228.6 369.52 226.33 373 227.24"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_36"
                    d="M378.73 228.29C379.039 227.996 379.406 227.769 379.808 227.624C380.21 227.479 380.637 227.42 381.063 227.449C381.489 227.478 381.904 227.595 382.283 227.793C382.661 227.992 382.994 228.266 383.26 228.6"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_37"
                    d="M374 239.18C372.561 239.226 371.137 239.478 369.77 239.93C367.5 240.69 364.18 239.78 365.69 241.75C367.2 243.72 370.07 244.32 373.09 244.32C376.11 244.32 378.84 244.17 379.75 242.81C380.66 241.45 382.01 240.09 380.96 239.93C379.91 239.77 375.82 239.48 375.36 239.48C374.897 239.43 374.441 239.33 374 239.18Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="Vector_38">
                    <path
                      d="M378 230.41C378 230.41 377.7 238.72 377.4 240.24C377.352 240.643 377.182 241.022 376.913 241.326C376.644 241.63 376.289 241.845 375.896 241.943C375.502 242.04 375.087 242.016 374.708 241.872C374.328 241.729 374.001 241.474 373.77 241.14C372.81 239.877 371.998 238.508 371.35 237.06"
                      fill="white"
                    />
                    <path
                      d="M378 230.41C378 230.41 377.7 238.72 377.4 240.24C377.352 240.643 377.182 241.022 376.913 241.326C376.644 241.63 376.289 241.845 375.896 241.943C375.502 242.04 375.087 242.016 374.708 241.872C374.328 241.729 374.001 241.474 373.77 241.14C372.81 239.877 371.998 238.508 371.35 237.06"
                      stroke="#263238"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <path
                    id="Vector_39"
                    d="M410.19 279.44C410.19 279.44 402.99 280.62 399.99 282.62C396.99 284.62 395.94 286.36 396.69 288.28C397.332 290.166 398.17 291.979 399.19 293.69C400.44 295.69 402.69 294.19 402.69 294.19C402.48 293.966 402.32 293.701 402.218 293.412C402.116 293.123 402.076 292.816 402.1 292.51C402.123 292.204 402.21 291.907 402.355 291.637C402.499 291.366 402.699 291.129 402.94 290.94C404.69 289.19 411.19 286.69 411.19 286.69H413.44C414.44 286.69 413.44 283.94 412.69 282.69C411.94 281.44 411.94 278.94 410.19 279.44Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_40"
                    d="M325.44 291.44C325.44 291.44 325.44 298.69 325.94 305.94C326.44 313.19 332.44 333.44 332.44 333.44L338.44 331.94C338.44 331.94 338.19 312.94 338.19 306.94C338.19 300.94 337.94 293.69 337.94 293.69L325.44 291.44Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_41"
                    d="M324.35 286.39C324.387 286.077 324.506 285.779 324.694 285.526C324.882 285.272 325.133 285.073 325.422 284.947C325.711 284.82 326.028 284.772 326.342 284.806C326.655 284.839 326.955 284.955 327.21 285.14C328.856 286.233 330.731 286.934 332.69 287.19C335.083 287.615 337.525 287.693 339.94 287.42C340.182 287.388 340.428 287.417 340.656 287.504C340.884 287.592 341.087 287.735 341.245 287.921C341.403 288.108 341.512 288.33 341.562 288.569C341.612 288.808 341.601 289.056 341.53 289.29C340.9 291.36 339.95 294.18 339.19 294.94C337.94 296.19 331.94 296.19 328.94 295.19C325.94 294.19 325.19 293.44 324.44 290.94C324.143 289.44 324.112 287.9 324.35 286.39Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_42"
                    d="M440.94 310.94C441.509 309.123 442.544 307.485 443.94 306.19C446.19 304.19 447.19 303.69 444.94 303.44C442.69 303.19 439.94 306.69 439.94 306.69L440.94 310.94Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_43"
                    d="M402.69 294.19C404.217 295.726 405.992 296.994 407.94 297.94C411.69 299.94 430.44 310.19 430.44 310.19L437.19 306.19C437.19 306.19 426.69 301.44 421.94 296.44C417.19 291.44 413.44 286.69 413.44 286.69C413.44 286.69 400.94 287.44 402.69 294.19Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_44"
                    d="M427.19 307.44C427.19 307.44 428.19 312.69 429.94 312.94C431.69 313.19 440.19 312.44 440.94 310.94C441.69 309.44 442.19 304.19 440.94 303.94C439.69 303.69 427.19 307.44 427.19 307.44Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_45"
                    d="M333.2 339.52C332.023 338.02 330.475 336.852 328.71 336.13C325.9 335.06 324.79 334.94 326.81 333.91C328.83 332.88 332.63 335.19 332.63 335.19L333.2 339.52Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_46"
                    d="M344.82 331.39C344.82 331.39 345.74 336.65 344.19 337.5C342.64 338.35 334.43 340.65 333.19 339.5C331.95 338.35 329.63 333.62 330.72 332.95C331.81 332.28 344.82 331.39 344.82 331.39Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <g id="freepik--character-1--inject-73">
                  <path
                    id="Vector_47"
                    d="M177.94 341.44C177.94 341.44 179.19 341.19 189.44 338.19C196.006 336.088 201.995 332.494 206.94 327.69L203.69 307.19L182.94 310.19L177.94 341.44Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_48"
                    d="M126.94 410.94C126.94 410.94 123.44 417.94 122.94 424.44C122.44 430.94 126.19 433.19 127.44 431.44C128.69 429.69 130.69 423.44 130.94 420.69L131.69 412.44L126.94 410.94Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_49"
                    d="M219 415.44C219 415.44 218 424.44 217.73 428.69C217.46 432.94 222.73 436.94 223.47 437.2C224.21 437.46 224.97 437.2 226.22 434.2C227.47 431.2 227.48 423.95 226.74 421.7C226 419.45 224.5 415.95 224.5 415.95L219 415.44Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_50"
                    d="M183.94 283.69C183.94 283.69 173.85 295.69 170.35 305.69C166.85 315.69 164.06 328.07 161.69 334.69C159.709 340.344 158.04 346.103 156.69 351.94C156.69 351.94 150.19 358.44 148.44 364.19C146.69 369.94 126.19 412.44 126.19 412.44C126.19 412.44 127.44 414.19 128.44 414.44C129.44 414.69 131.69 413.44 131.69 413.44C131.69 413.44 167.94 365.94 170.19 362.44C172.44 358.94 179.54 350.84 180.29 349.59C181.04 348.34 186.69 330.94 188.69 328.44C190.171 326.447 191.47 324.326 192.57 322.1L194.07 323.1L195.82 323.35C195.82 323.35 203.69 339.19 205.69 345.69C207.69 352.19 213.44 387.69 214.94 394.19C216.44 400.69 218.44 417.69 218.44 417.69C219.343 417.987 220.261 418.238 221.19 418.44C222.44 418.69 225.69 417.69 225.69 417.69C225.69 417.69 231.44 391.69 231.69 382.69C231.94 373.69 230.19 353.69 230.19 350.69C230.19 347.69 225.69 306.44 223.44 300.94C221.19 295.44 213.69 285.69 213.69 285.69L183.94 283.69Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_51"
                    d="M198.69 236.44L183.69 241.19C183.69 241.19 183.69 270.19 183.94 273.94C184.19 277.69 182.19 283.94 183.44 285.44C184.69 286.94 190.69 292.94 201.69 293.19C212.69 293.44 215.69 289.94 215.69 289.94L217.94 280.19C217.94 280.19 225.19 260.69 225.19 257.69C225.19 254.69 217.94 245.94 213.69 242.44C209.44 238.94 198.69 236.44 198.69 236.44Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_52"
                    d="M159.94 270.69C156.746 272.84 153.405 274.761 149.94 276.44L129.94 286.44L132.19 291.19C137.068 290.319 141.993 289.735 146.94 289.44C153.44 289.19 164.44 284.94 165.19 283.69C165.94 282.44 168.44 275.69 168.44 275.69L159.94 270.69Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_53"
                    d="M196.69 236.69C196.69 236.69 185.94 266.19 185.44 270.44C184.94 274.69 180.69 285.44 180.44 286.94C180.19 288.44 173.19 303.69 173.19 303.69C173.19 303.69 180.69 280.19 181.44 275.44C181.85 272.403 181.507 269.312 180.44 266.44C180.44 266.44 175.37 277.2 174.62 277.95C173.87 278.7 168.78 287.29 168.78 287.29L159.94 270.69C159.94 270.69 169.69 253.69 171.69 250.94C173.69 248.19 177.94 240.69 180.94 239.19C183.94 237.69 196.69 236.69 196.69 236.69Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_54"
                    d="M211.44 240.44C211.44 240.44 203.69 272.94 203.69 278.19C203.69 283.44 202.94 299.69 202.19 308.69C201.44 317.69 199.94 346.44 199.94 346.44C204.737 347.175 209.604 347.343 214.44 346.94C221.44 346.19 233.44 341.44 233.44 341.44C233.44 341.44 227.19 309.94 224.69 302.44C222.587 295.955 220.835 289.363 219.44 282.69C219.44 281.94 226.19 259.94 226.19 256.94C226.19 253.94 214.19 241.19 211.44 240.44Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_55"
                    d="M123.28 288.32C123.429 286.425 123.085 284.523 122.28 282.8C120.92 280.12 120.18 279.29 122.36 279.88C124.54 280.47 125.82 284.74 125.82 284.74L123.28 288.32Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_56"
                    d="M137.35 290.12C137.35 290.12 134.49 294.64 132.77 294.23C131.05 293.82 123.42 290 123.28 288.32C123.14 286.64 124.59 281.59 125.85 281.81C127.11 282.03 137.35 290.12 137.35 290.12Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_57"
                    d="M222 209.77C222.652 211.71 223.143 213.7 223.47 215.72C223.377 216.951 223.149 218.168 222.79 219.35C222.79 219.35 226.88 222.99 227.11 223.9C227.34 224.81 224.84 226.17 224.84 226.17C224.84 226.17 225.06 235.71 225.06 236.39C225.06 237.07 213.93 237.53 213.93 237.53L202.3 251.16L196.85 237.76L199.35 230.26C199.35 230.26 191.85 225.94 194.58 219.35C197.31 212.76 203.44 209.13 209.58 208.22C213.577 207.697 217.633 207.849 221.58 208.67L222 209.77Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_58"
                    d="M213.89 237.53L208.19 236.44L207.86 244.11L213.89 237.53Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_59"
                    d="M220.42 220.23C220.42 221.03 220.13 221.67 219.76 221.67C219.39 221.67 219.1 221.03 219.1 220.23C219.1 219.43 219.4 218.8 219.76 218.8C220.12 218.8 220.42 219.44 220.42 220.23Z"
                    fill="#263238"
                  />
                  <path
                    id="Vector_60"
                    d="M218.22 217.92C218.22 217.92 218.88 216.6 221.3 217.26"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_61"
                    d="M223.73 228.5C223.73 228.5 220.86 228.94 219.73 227.39"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_62"
                    d="M222.52 212.54C222.52 212.54 215.03 210.72 213.66 213.45C212.29 216.18 213.44 217.54 212.3 220.04C211.16 222.54 206.85 225.72 206.85 225.72C206.85 225.72 202.76 221.17 201.39 223.44C200.02 225.71 200.48 229.12 201.85 230.26C203.22 231.4 207.07 230.71 207.07 230.71L201.17 233.44C201.17 233.44 201.39 236.85 195.94 238.21C190.49 239.57 183.94 235.94 184.81 231.4C185.68 226.86 193.21 224.13 193.21 224.13C193.21 224.13 185.21 214.36 191.85 207.13C198.49 199.9 211.39 203.27 211.39 203.27C211.39 203.27 213.21 201.27 218.39 200.77C223.57 200.27 225.89 205.54 225.89 208.5C225.89 211.46 222.52 212.54 222.52 212.54Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_63"
                    d="M216.82 294.73L215.45 300C213.896 302.99 212.096 305.847 210.07 308.54C206.77 312.91 194.92 325.1 194.92 325.1L191.57 329.23L191.18 332.85L193.9 333.9C193.9 333.9 195.26 334.43 196.37 335C197.48 335.57 200.37 336.09 201.26 335.42C202.15 334.75 204.26 329.32 204.26 329.32L205.06 325.13C205.06 325.13 219.14 314.08 222.36 310.73C225.58 307.38 228.36 304.37 229.3 302.43C230.24 300.49 229.47 294.54 229.47 294.54L216.82 294.73Z"
                    fill="white"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="Vector_64">
                    <path
                      d="M216.47 269.17L214 295.73C216.352 296.576 218.757 297.264 221.2 297.79C225.15 298.63 233.4 296.45 233.4 296.45C233.4 296.45 232.13 274.34 230.48 266.25C228.83 258.16 224.23 252.11 221.27 251.1"
                      fill="#4A8ACF"
                    />
                    <path
                      d="M216.47 269.17L214 295.73C216.352 296.576 218.757 297.264 221.2 297.79C225.15 298.63 233.4 296.45 233.4 296.45C233.4 296.45 232.13 274.34 230.48 266.25C228.83 258.16 224.23 252.11 221.27 251.1"
                      stroke="#263238"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
                <g id="freepik--Floor--inject-73">
                  <path
                    id="Vector_65"
                    d="M533.19 431.44H714.19"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_66"
                    d="M511.19 431.44H524.19"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_67"
                    d="M184.19 431.44H500.19"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_68"
                    d="M33.1899 431.44H175.19"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <g id="inline_id">
                  <path
                    id="Vector_69"
                    d="M599.675 135.58L597.005 84L552.665 108.09L553.665 112.09L570.435 120.65L491.525 275.3L241.085 343.4L67.9549 225.24C66.4207 224.192 64.5331 223.797 62.7074 224.141C60.8817 224.485 59.2675 225.541 58.2199 227.075C57.1723 228.609 56.7771 230.497 57.1213 232.323C57.4654 234.148 58.5207 235.762 60.0549 236.81L235.815 356.78C236.993 357.585 238.388 358.01 239.815 358C240.434 358.001 241.049 357.917 241.645 357.75L498.195 288C499.137 287.744 500.015 287.295 500.772 286.68C501.53 286.065 502.151 285.299 502.595 284.43L582.915 127.05L599.675 135.58Z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_70"
                    d="M598.675 131.58L596.005 80L552.665 108.09L569.435 116.65L490.525 271.3L240.085 339.4L66.9549 221.24C65.4207 220.192 63.5331 219.797 61.7074 220.141C59.8817 220.485 58.2675 221.541 57.2199 223.075C56.1723 224.609 55.7771 226.497 56.1213 228.323C56.4654 230.148 57.5207 231.762 59.0549 232.81L234.815 352.78C235.993 353.585 237.388 354.01 238.815 354C239.434 354.001 240.049 353.917 240.645 353.75L497.195 284C498.137 283.744 499.015 283.295 499.772 282.68C500.53 282.065 501.151 281.299 501.595 280.43L581.915 123.05L598.675 131.58Z"
                    fill="#4A8ACF"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_71"
                    d="M591.465 88.9302L562.655 107.58L576.165 114.47L493.975 275.58L239.165 344.83L64.165 225.37C63.9477 225.222 63.7034 225.119 63.446 225.065C63.1887 225.012 62.9233 225.01 62.6651 225.059C62.4069 225.108 62.161 225.208 61.9413 225.352C61.7216 225.496 61.5326 225.683 61.385 225.9C61.0853 226.338 60.9717 226.877 61.0693 227.398C61.1668 227.919 61.4674 228.38 61.905 228.68L237.705 348.68C238.038 348.908 238.432 349.03 238.835 349.03C239.01 349.027 239.185 349.003 239.355 348.96L495.905 279.2C496.173 279.13 496.423 279.005 496.64 278.832C496.857 278.66 497.036 278.445 497.165 278.2L579.755 116.37L593.265 123.26L591.465 88.9302Z"
                    fill="white"
                  />
                </g>
                <path
                  id="outline_id"
                  d="M61.5 226L239 347L495.5 277.5L578.5 115L563.5 107.5L591 89.5L593 122.5L579.5 115.5"
                  stroke="#356BB3"
                  strokeWidth="6"
                />
              </g>
            </g>
          </svg>
          <div className="hero_coins">
            <div className="coin_1">
              <Coin front={dolar} x={0.1} y={0.3} z={0.2} />
            </div>
            <div className="coin_2">
              <Coin front={eu} x={0.3} y={0.1} z={0.3} />
            </div>
            <div className="coin_3">
              <Coin front={ru} x={0.2} y={0.2} z={0.4} />
            </div>
            <div className="coin_4">
              <Coin front={yen} x={0.1} y={0.3} z={0.2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
